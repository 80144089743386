import React from "react";

function Missing() {
  return (
    <>
      <h1 className="files-upload Home">This Page Doesn't Exist yet!</h1>
    </>
  );
}

export default Missing;
