import { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import axiosInstance from "../api/axios";

function Home() {
  return (
    <>
      <h1>Home</h1>
    </>
  );
}

export default Home;
